@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
  /*background-color: white;*/
  font-family: Poppins, sans-serif;


  /*background-image: linear-gradient(-225deg, #B7F8DB 0%, #50A7C2 100%);*/
  /*background-image: linear-gradient(-225deg, #CBBACC 0%, #2580B3 100%);*/
}

:root {
  --font-family:'Poppins', sans-serif !important;
}

.p-component {
  font-family: 'Poppins', sans-serif !important;
}

.p-link {
  font-family: 'Poppins', sans-serif !important;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
  font-family: 'Poppins', sans-serif !important;
}

.p-chips .p-chips-multiple-container .p-chips-input-token input {
  font-family: 'Poppins', sans-serif !important;
}

.p-inputtext {
  font-family: 'Poppins', sans-serif !important;
}

.p-terminal .p-terminal-input {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.p-breadcrumb .p-breadcrumb-chevron {
  font-family: 'Poppins', sans-serif !important;
}

.p-datatable .p-sortable-column.p-highlight {
  background: #ffffff;
  color: #4f959d!important;
}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #4f959d!important;
}
.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
  background: #e9ecef;
  color: #4f959d!important;
}
.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover .p-sortable-column-icon {
  color: #4f959d!important;
}

.p-picklist .p-picklist-list .p-picklist-item.p-highlight {
  color: #ffffff;
  background: #4f959d!important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #ffffff;
  background: #4f959d!important;
}

