@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.poppins-font {
  font-family: 'Poppins', sans-serif!important;
  font-size: 12px!important;
  font-weight: 400!important;
}

//span:has(> div.weekend) {
//  background-color: lightblue;
//}


#date-card {
  .p-card-body {
    text-wrap: nowrap;
    margin-top: auto;
  }
}

#header-card {
  .p-card-body {
    margin-top: auto;
  }
}

.p-card {
  .p-card-body {
    padding: 0px!important;
  }
}

.p-datepicker table td  {
  .p-disabled {
    color: grey;
    opacity: 0.4!important;
  }
}

.p-multiselect-item.p-highlight {
  color: black!important;
  background: transparent!important;
}